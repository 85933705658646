import React from 'react'
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import { FaFacebookF, FaLinkedinIn, FaMailBulk, FaPhoneAlt } from "react-icons/fa"
import { MdLocationPin } from "react-icons/md"
import { SiInstagram } from 'react-icons/si'
import { BsPinterest } from 'react-icons/bs'
import { AiFillYoutube } from 'react-icons/ai'



function ContactPage() {
  return (
    <>
    {/* <Navbar /> */}
    <Topbar />
    <div className="h-full w-full bg-white">
        <div className="w-full flex flex-col  px-0 md:px-0">
            {/* <p className='font-satoshi uppercase text-[16px] md:text-[18px] font-semibold'>Contact us</p> */}
            <div className="map h-[700px]">
        <iframe title='zawadiMap' className=" w-full h-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31917.146960539103!2d37.44324402899026!3d-0.5365620749460983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18262de0b161c82f%3A0x86c79bba0e2b8896!2sEmbu!5e0!3m2!1sen!2ske!4v1665775971647!5m2!1sen!2ske" 
         />

        </div>
        <div className="container mx-auto">

            <div className="flex flex-col md:flex-row w-full py-4 gap-4">
                <div className="bg-white font-satoshi rounded-md shadow-sm w-full md:w-[50%] px-4 py-4">
                
            <p className='font-satoshi  text-[16px] md:text-[24px] font-medium'>Contact info</p>

                    <div className="flex flex-col gap-6 w-full pt-10 md:pt-20">
                        <div className="flex flex-row gap-3">
                            <MdLocationPin size={30} />
                        {/* loacation details */}
                        <p className='font-satoshi text-[16px] md:text-[20px] font-medium'>Nairobi, Kenya</p>


                        </div>
                    
                        <div className="flex flex-row gap-3">
                            <FaPhoneAlt size={25}/>

                            {/* phone */}
                        <p className='font-satoshi text-[16px] md:text-[20px] font-medium'>+254707031397</p>

                        </div>
                   
                        <div className="flex flex-row gap-3">
                            <FaMailBulk size={30}/>

                            {/* email */}
                        <p className='font-satoshi text-[16px] md:text-[20px] font-medium'>info@wavegridstudion.com</p>

                        </div>
                   
                        <div className="flex flex-row gap-3 pt-6 md:pt-40">
                            <a href='https://stackoverflow.com/' target='_blank' rel="noreferrer">
                            {/* <img className='grayscale' src="/cdn/images/facebook.svg" alt="" /> */}
                            <FaFacebookF size={25}  />
                            </a>

                            <a href='https://stackoverflow.com/' target='_blank' rel="noreferrer">
                            {/* <img className='grayscale' src="/cdn/images/instagram.svg" alt="" /> */}
                            <SiInstagram size={25} />
                            </a>

                            <a href='https://stackoverflow.com/' target='_blank' rel="noreferrer">
                            {/* <img className='grayscale' src="/cdn/images/linkedin.svg" alt="" /> */}
                            <FaLinkedinIn size={25} />
                            </a>

                            <a href='https://stackoverflow.com/' target='_blank' rel="noreferrer">
                            {/* <img className='grayscale' src="/cdn/images/pinterest.svg" alt="" /> */}
                            <BsPinterest size={25} />
                            </a>

                            <a href='https://stackoverflow.com/' target='_blank' rel="noreferrer">
                            {/* <img className='grayscale' src="/cdn/images/youtube.svg" alt="" /> */}
                            <AiFillYoutube size={25} />
                            </a>

                            {/* social */}
                        </div>
                    </div>
                    
                </div>
                <div className="bg-white rounded-md shadow-sm w-full md:w-[50%] px-4 py-4">
                <p className='font-satoshi text-[16px] md:text-[24px] font-medium py-2'>Have a Project in Mind?</p>
                <p className='font-satoshi text-[16px] md:text-[18px] font-medium py-1'>Please fill in the details below and we will get back to you shortly.</p>


                    <form action="" className='w-full flex flex-col gap-2'>
                        <div className="w-full flex flex-col gap-1">
                            <label htmlFor="name" className='font-satoshi text-[16px] md:text-[18px]'>Name</label>
                            <input type="text" name="name" id=""
                            placeholder='Enter your name'
                            className='w-full outline-none rounded-md px-4 py-2 border font-satoshi text-[16px] md:text-[18px]' />
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <label htmlFor="email" className='font-satoshi text-[16px] md:text-[18px]'>Email</label>
                            <input type="text" name="email" id=""
                            placeholder='Enter your email'
                            className='w-full outline-none rounded-md px-4 py-2 border font-satoshi text-[16px] md:text-[18px]' />
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <label htmlFor="subject" className='font-satoshi text-[16px] md:text-[18px]'>Subject</label>
                            <input type="text" name="subject" id=""
                            placeholder='Enter subject'
                            className='w-full outline-none rounded-md px-4 py-2 border font-satoshi text-[16px] md:text-[18px]' />
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <label htmlFor="message" className='font-satoshi text-[16px] md:text-[18px]'>Message</label>
                            <textarea type="text" name="name" id=""
                            placeholder='Write your message'
                            rows={5}
                            className='w-full outline-none rounded-md px-4 py-2 border font-satoshi text-[16px] md:text-[18px]' />
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <button className='px-4 py-2 font-satoshi text-[18px] text-white rounded-md text-center outline-none bg-slate-800'>Send message</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default ContactPage