import React from 'react'
import Category from '../components/Category'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Topbar from '../components/Topbar'

function CategoryPage() {
  return (
    <>
    {/* <Navbar /> */}
    <Topbar />
    <Category />
    <Footer />
    </>
  )
}

export default CategoryPage