import React, { useEffect, useState } from 'react'
import mm from  '../assets/m.jpg'


function Hero({ handleClose,images, activeId}) {

  const [currentIndex, setCurrentIndex] = useState(activeId)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const nextSlide = isFirstSlide ? images.length - 1 : currentIndex - 1
    setCurrentIndex(nextSlide)


  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
    
  }
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)

  }
  return (
    <>

    <div className="w-full h-screen relative z-50">
        {/* <video src={milk} className='w-full h-full object-cover' autoPlay loop muted /> */}
        <img src={images[currentIndex].url} loading='lazy' alt="" className={`w-full h-screen ${isMobile ? 'object-cover':''}`} />

        <div className="absolute flex flex-row text-white place-items-center w-full h-full justify-between  top-0 left-0 p-4">
        <div
        onClick={handleClose}
         className="absolute  top-4 right-5 text-2xl bg-gray-900/80 rounded-full p-4  text-white cursor-pointer ">


        <svg
        handleClick={handleClose}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="cursor-pointer  w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
      </svg>


        </div>
        <div
        onClick={prevSlide}
         className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl bg-gray-900/80 rounded-full p-4 bg-black/2 text-white cursor-pointer">

        <svg
        onClick={prevSlide}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        </div>

        <div
        onClick={nextSlide}
         className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl bg-gray-900/80 rounded-full p-4 bg-black/2 text-white cursor-pointer">

        <svg
        onClick={nextSlide}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
        </svg>
        </div>

        </div>
    </div>
    {/* <div modalbackdrop='' className='h-screen bg-white bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40' /> */}

    </>
  )
}

export default Hero