import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { ENDPOINT_PORTFOLIO } from '../routes';
// import { useGetGalleryQuery } from '../features/products/productsApiSlice';


function Gallery() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);
  // const { data: galleries, isLoading, isSuccess, isError, error } = useGetGalleryQuery()
    const images = [
        {'id':1,
          'file':'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'  },
          {'id':2,
          'file':'/cdn/images/ks1.png'  },
          {'id':3,
          'file':'/cdn/images/ks2.png'  },
          {'id':4,
          'file':'/cdn/images/ks3.png'  },
          {'id':5,
          'file':'/cdn/images/ks4.png'  },
          {'id':6,
          'file':'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'  },
    ]
    const [open, setOPen] = useState([]);
    const [currentIndex, setCurrentIndex] = useState([]);

    const cancelRef = useRef()

    const handleShowOrderChange = orderID => {
        const openState = open.slice()
        const index = openState.indexOf(orderID)
    
        if (index >= 0) {
          openState.splice(index, 1)
          setOPen(openState)
        } else {
          openState.push(orderID)
          setOPen(openState)
        }
      }
  
    useEffect(() => {
        let handler = e => {
          if (cancelRef.current && !cancelRef.current.contains(e.target)) {
    
            setOPen([])
    
            console.log(cancelRef.current)
          }
        }
        document.addEventListener('mousedown', handler);
    
        return () => {
          document.removeEventListener('mousedown', handler);
        }
    
      })

      const handlePrevSlide = () => {
        const imgLength = images.length;
        setCurrentIndex(currentIndex === 0 ? imgLength - 1 : currentIndex - 1);
      }
      const handleNextSlide = () => {
        setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
      }
      console.log('currentIndex', currentIndex)
      console.log(images[currentIndex]?.file)
  return (
    <div className={`flex flex-col w-full py-4 px-2 md:px-0  py-2 md:py-6 custom-scrollbar ${isMobile? 'h-screen':'h-full'}`} >
        <div className="flex flex-col w-full gap-2 h-full">

            <div className="grid grid-cols-2 md:grid-cols-3 h-full gap-1 w-full ">
                {images?.map((gallery) => (
                    <>
                    <div className="relative h-full">

                    <div className="absolute z-10 inset-0 flex duration-300 transition opacity-0 hover:opacity-100 ">
                      <div className="inset-0 absolute bg-black/70 opcity-25"></div>
                      
                      <div className="mx-auto self-center z-10 tracking-widest cursor-pointer font-satoshi text-white ">
                      <Link to={`${ENDPOINT_PORTFOLIO}/${1}`}>
                        <p className="font-satoshi md:text-[24px] text-[16px]">James</p>
                      </Link>
                      </div>
                    </div>
                    

                <div
                onClick={() => {handleShowOrderChange(gallery.id)
                    setCurrentIndex(gallery.id)
                }}
                 key={gallery.id} className="h-full  col-span-1 relative rounded-md shadow-sm hover:scale-105 ease-in-out duration-300">
                    <img src={gallery.file} loading='lazy' className='h-full  relative object-cover' alt="" />
                   
                </div>
                </div>


{open.includes(gallery.id) &&

    <>
    <div className='h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
                  <div className='relative p-4 w-full -ml-20 md:-ml-0 md:w-[60%] h-screen md:h-auto'>
                    <div ref={cancelRef} className='border-0  relative flex flex-col w-full  outline-none focus:outline-none'>
                      <div className='flex items-start justify-end p-2 rounded-t '>
                      </div>
    
                      <div className='relative p-3 flex-auto'>
                        <div className='px-4 pt-4 pb-3 w-full'>
    
                          <div className="flex flex-col gap-2">
                            <div onClick={() => setOPen([])} className=" pl-4">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-6 h-6 float-right cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                            <div className="flex flex-row gap-2 w-full">
                           
                            <div 
                             onClick={handlePrevSlide}
                            className="flex justify-center place-items-center items-center cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                </svg>

                              </div>
    
                              <img src={images[currentIndex]?.file} alt="" className='w-screen h-screen object-contain' />
                              <div 
                              onClick={handleNextSlide}
                              className="flex justify-center place-items-center items-center cursor-pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                              </div>
    
                            </div>
    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40' />
    </>
    }
    </>
                ))}

            </div>

        </div>
 
    </div>
  )
}

export default Gallery