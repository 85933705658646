import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_CONTACT_US, ENDPOINT_HOMEPAGE, ENDPOINT_PORTFOLIO } from '../routes'
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"
import { BsPinterest } from "react-icons/bs"
import { SiInstagram } from "react-icons/si"
import { AiFillYoutube } from "react-icons/ai"





function Footer() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  return (
    <div className={`w-full bg-gray-800/10 pt-4 ${isMobile? '':''}`}>
        <div className=" py-4 px-2 md:px-6 flex justify-center items-center place-items-center ">
            <div className="flex flex-col gap-4 justify-between place-itemss-start md:place-items-center items-start md:items-center">
                {/* logo */}

                <div className="col-span-1 ">
                {/* <p className='font-satoshi text-[16px] md:tex-[20px] text-white font-medium'>Social media links</p> */}
                <div className="flex flex-col gap-1 py-4">
                    <div className="flex flex-row gap-2">
                        <a href="https://www.wavegridstudio.com" target='_blank' rel="noreferrer" >
                          <FaFacebookF size={isMobile? 18 : 25} color='white' />

                            {/* <img src="/cdn/images/facebook.svg" className='grayscale rounded-full w-12 h-12' alt="" /> */}
                        </a>
                            <a href="https://www.wavegridstudio.com" target='_blank' rel="noreferrer">
                              <SiInstagram size={isMobile? 18 : 25} color='white'/>

                            {/* <img src="/cdn/images/instagram.svg" className='grayscale rounded-full w-12 h-12' alt="" /> */}
                            </a>
                            <a href="https://www.wavegridstudio.com" target='_blank' rel="noreferrer">
                              <FaLinkedinIn size={isMobile? 18 : 25} color='white'/>

                            {/* <img src="/cdn/images/linkedin.svg" className='grayscale rounded-full w-12 h-12' alt="" /> */}
                            </a>
                            <a href="https://www.wavegridstudio.com" target='_blank' rel="noreferrer">
                            <AiFillYoutube size={isMobile? 18 : 25} color='white' />
                            {/* <img src="/cdn/images/youtube.svg" className='grayscale rounded-full w-12 h-12' alt="" /> */}
                            </a>
                           

                        
                    </div>
                </div>

                </div>
                <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi font-medium text-[16px] md:text-[24px]'>
            TEL: +254 707 031 397
          </div>
        </div>

        <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi font-semibold uppercase text-[16px] md:text-[20px]'>
            wAVEGRID STUDIO
          </div>
        </div>

        <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi font-medium text-[16px] md:text-[20px]'>
            The Spur Mall, Thika Road, Nairobi
          </div>
        </div>

        <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi underline underline-offset-4 font-medium text-[16px] md:text-[20px]'>
          TEL: +254 707 031 397
          </div>
        </div>
        <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi underline underline-offset-4 font-medium text-[16px] md:text-[20px]'>
          TEL: +254 707 031 397
          </div>
        </div>
        <div className='w-full text-start md:text-center'>
          <div className='text-white font-satoshi underline underline-offset-4 font-medium text-[16px] md:text-[20px]'>
            info@wavegridstudio.com
          </div>
        </div>

        <div className='w-full text-start md:text-center flex flex-col md:flex-row gap-4'>
          <Link to={ENDPOINT_HOMEPAGE}>

          <p className='text-white font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            Home
          </p>
          </Link>
          <div
           className="border-r-2 border-r-white"></div>

          <p
          onClick={() => {
            const anchor = document.querySelector('#about')
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
           }}
           className='text-white cursor-pointer font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            About us
          </p>
          <div className="border-r-2 border-r-white"></div>
          <Link to={ENDPOINT_PORTFOLIO}>
          <p className='text-white font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            projects
          </p>
          </Link>
          <div className="border-r-2 border-r-white"></div>

          <p className='text-white font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            animation
          </p>
          <div className="border-r-2 border-r-white"></div>
          <p className='text-white font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            virtual reality
          </p>
          <div className="border-r-2 border-r-white"></div>
           <Link to={ENDPOINT_CONTACT_US}>

          <p className='text-white font-satoshi underline underline-offset-4 uppercase font-semibold text-[16px] md:text-[20px]'>
            Conatct
          </p>
           </Link>
          <div className="border-r--2 border-r-white"></div>
        </div>



            <div className='w-full text-center pt-2'>
          <div className='text-white font-satoshi text-[16px] md:text-[20px]'>
            &copy; {new Date().getFullYear()} Wavegridstudio. All rights reserved
          </div>
        </div>
            </div>
        </div>
    </div>
  )
}

export default Footer