import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_PORTFOLIO } from '../routes'

function OptionTwoPortfolio() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);

    const images = [
        {'id':1,
          'file':'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'  },
          {'id':2,
          'file':'/cdn/images/ks1.png'  },
          {'id':3,
          'file':'/cdn/images/ks2.png'  },
          {'id':4,
          'file':'/cdn/images/ks3.png'  },
          {'id':5,
          'file':'/cdn/images/ks4.png'  },
          {'id':6,
          'file':'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'  },
    ]
  return (
    <div className={`w-full  px-2 md:px-0 flex flex-col gap-1 ${isMobile? 'h-screen':'h-full'}`}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-1 w-full h-full">
            {images.map((project) => (

            <div className="relative col-span-1 w-full h-full">

            <img src={project.file} className='h-full w-full object-cover relative' alt="" />
            <div className="bg-gray-900/30 absolute top-0 left-0 w-full h-full">

            <Link to={`${ENDPOINT_PORTFOLIO}/${1}`}>
                <p className="font-satoshi text-[18px] md:text-[32px] z-50 absolute left-4 text-white font-semibold bottom-4">Victor madaraka</p>
                        
            </Link>
            </div>
            </div>
            ))}




        </div>
    </div>
  )
}

export default OptionTwoPortfolio