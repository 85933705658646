import { Route, Routes } from "react-router-dom";
import CategoryPage from "./pages/CategoryPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
import PortfolioPage from "./pages/PortfolioPage";
import { ENDPOINT_CATEGORY_PORTFOLIO, ENDPOINT_CONTACT_US, ENDPOINT_HOMEPAGE, ENDPOINT_PORTFOLIO, ENDPOINT_PORTFOLIO_DETAILS } from "./routes";
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
    <>
    <Routes>
      <Route element={<ComingSoon />} path={ENDPOINT_HOMEPAGE} />
      <Route element={<PortfolioPage />} path={ENDPOINT_PORTFOLIO} />
      <Route element={<ContactPage />} path={ENDPOINT_CONTACT_US} />
      <Route element={<PortfolioDetailsPage />} path={ENDPOINT_PORTFOLIO_DETAILS} />
      <Route element={<CategoryPage />} path={ENDPOINT_CATEGORY_PORTFOLIO} />


      


    </Routes>
    </>
  );
}

export default App;
