import React from 'react'
import { FaLinkedinIn } from 'react-icons/fa';
import { FaInstagram, FaPinterest, FaSquarePhone } from "react-icons/fa6";
import { MdEmail } from 'react-icons/md';

const ComingSoon = () => {
  return (
    <div className='h-screen w-screen bg-gradient-to-br from-[#E5D6EF] via-[#F9F9FE] to-[#F9F9FE] flex flex-col justify-center items-center p-2 bg-cover bg-center' 
    style={{ backgroundImage: "url('/cdn/images/hero.jpg')" }}>
        <div className="flex flex-col w-full h-full flex-1 justify-center items-center"> {/* Optional dark overlay */}
          <p className='text-center font-satoshi'>WE'RE STILL</p>
          <p className='font-bold text-[#0C45DE] text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center'>Cooking Our Website.</p>
          <p className='text-gray-600 font-satoshi pt-3 text-lg md:text-xl text-center'>We are going to launch our website Very soon.</p>
          <p className='text-gray-600 font-satoshi text-lg md:text-xl text-center'>Stay Tuned.</p>
        </div>
        <div className="flex flex-col justify-end items-center w-full py-8">
          <div className="flex flex-row gap-2 items-center">
            <a href="https://www.instagram.com/wavegrid.studio?igsh=YWdmMW5odTByNXlu" target='_blank' className='rounded-full p-2 shadow-md flex flex-col justify-center items-center border border-gray-100 bg-white'>
              <FaInstagram size={20} className='text-gray-600'/>
            </a>
            <a href="https://www.linkedin.com/company/wavegridstudio/" target='_blank' className='rounded-full p-2 shadow-md flex flex-col justify-center items-center border border-gray-100 bg-white'>
              <FaLinkedinIn size={20} className='text-gray-600'/>
            </a>
            <a href="tel:+254707031397" className='rounded-full p-2 shadow-md flex flex-col justify-center items-center border border-gray-100 bg-white'>
              <FaSquarePhone size={20} className='text-gray-600'/>
            </a>
            <a href="mailto:info@wavegridstudio.com" target='_blank' className='rounded-full p-2 shadow-md flex flex-col justify-center items-center border border-gray-100 bg-white'>
              <MdEmail size={20} className='text-gray-600'/>
            </a>
            <a href="https://pin.it/7KAjRtoZ6" target='_blank' className='rounded-full p-2 shadow-md flex flex-col justify-center items-center border border-gray-100 bg-white'>
              <FaPinterest size={20} className='text-gray-600'/>
            </a>
            
          </div>
        </div>
    </div>
  )
}

export default ComingSoon
