import React from 'react'
import { useParams } from 'react-router-dom'
import Gallery from './Gallery'

function Category() {
  const { categoryName } = useParams()
  return (
    <div className='flex flex-col w-full custom-scrollbar py-5 mb:py-10'>
         <div className="flex flex-col w-full pt-4 pb-10">
          <p className='text-white capitalize text-[16px] md:text-[24px] text-left md:text-center py-2 px-4 md:px-0'>{categoryName}</p>
            <Gallery />
         </div>
    </div>
  )
}

export default Category