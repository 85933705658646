import React, { useState } from 'react'
import OptionTwoPortfolio from '../pages/OptionTwoPortfolio'
import Gallery from './Gallery'

const ALL_TAB = 'all'
const ANIMATIONS_TAB = 'animations'
const FLY_THROUGH_TAB = 'fly throughs'
const INTERIOR_TAB = 'interior'
const EXTERIOR_TAB = 'exterior' 
const THREED_TAB = '3d renders'

function Portfolio() {
    const [currentTab, setCurrentTab] = useState(ALL_TAB)
    const [showNav, setShowNav] = useState(false)
  return (
    <div className='flex flex-col w-full px-2 md:px-0 h-full custom-scrollbar'>

        <div
        onClick={() => setShowNav(false)}
         className={` ${showNav ? 'flex md:hidden' : ''} flex overflow-x-scroll md:overflow-hidden custom-sscrollbar w-full flex-row gap-3 justify-start md:justify-center items-start md:items-center px-4 md:px-0 py-2 md:py-4`}>
            <div
            onClick={() => setCurrentTab(ALL_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === ALL_TAB ? 'text-[#1CC7C0]':'text-white' }`}>All</div>
            <div
            onClick={() => setCurrentTab(ANIMATIONS_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === ANIMATIONS_TAB ? 'text-[#1CC7C0]' :'text-white'} `}>animations</div>
            <div
            onClick={() => setCurrentTab(FLY_THROUGH_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === FLY_THROUGH_TAB ? 'text-[#1CC7C0]' :'text-white'} `}>fly throughs</div>
            <div
            onClick={() => setCurrentTab(INTERIOR_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === INTERIOR_TAB ? 'text-[#1CC7C0]' :'text-white'} `}>interior renders</div>
            <div
            onClick={() => setCurrentTab(EXTERIOR_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === EXTERIOR_TAB ? 'text-[#1CC7C0]' :'text-white'} `}>exterior renders</div>
             <div
            onClick={() => setCurrentTab(THREED_TAB)}
             className={`cursor-pointer font-satoshi capitalize text-[12px] md:text-[18px] ${currentTab === THREED_TAB ? 'text-[#1CC7C0]' :'text-white'} `}>3d renders</div>


        </div>

        <div className="flex flex-col w-full h-full py-2 md:py-6">
            {currentTab === EXTERIOR_TAB ?
            <>

            {/* <OptionTwoPortfolio /> */}
            <Gallery />
            </>

            : currentTab === INTERIOR_TAB ?
            <OptionTwoPortfolio />
            : currentTab === FLY_THROUGH_TAB ?
            <Gallery />
            : currentTab === ANIMATIONS_TAB ?
            <OptionTwoPortfolio />
            : currentTab === THREED_TAB ?
            <>
            <OptionTwoPortfolio />

            <Gallery />
            </>
            :
            <>
            <Gallery />
            <OptionTwoPortfolio />
            </>
            }
        </div>

    </div>
  )
}

export default Portfolio