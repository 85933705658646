import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ENDPOINT_CONTACT_US, ENDPOINT_HOMEPAGE, ENDPOINT_PORTFOLIO } from '../routes'

function Topbar() {
    const { pathname } = useLocation()
    const [showMenu, setShowMenu] = useState(false)
  return (
    <nav className='p-3 bg-gray-900/30 z-50 md:flex md:justify-between md:items-center'>
        <div className="flex justify-between items-center">
        <img src="/cdn/images/wave1.png" alt="logo" className='h-10 md:h-12 w-48 md:w-60 py-0.5' />

        <div className="cursor-pointer md:hidden block">
        {!showMenu ?
        <svg
        onClick={() => setShowMenu(true)}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-9 h-9">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
        </svg>
        :
        <svg
        onClick={() => setShowMenu(false)}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-9 h-9">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        }

        </div>


        </div>
        <ul className={`md:flex md:items-center gap-6 md:gap-3 px-4 z-[-1] md:z-auto transition-all ease-in duration-500  ${showMenu? 'h-screen w-full py-20 flex flex-col items-end z-50':'hidden'}`}>
            <Link to={ENDPOINT_HOMEPAGE}>
            <li className={` font-satoshi font-medium  text-white text-[16px] md:text-[16px] capitalize hover:text-[#1CC7C0] md:capitalize my-3 md:my-0 ${pathname  === ENDPOINT_HOMEPAGE ? 'text-[#1CC7C0]':'text-white' } `}>Home</li>
            </Link>

            <Link to={ENDPOINT_PORTFOLIO}>
            <li className={` font-satoshi font-medium  text-white text-[16px] md:text-[16px] capitalize hover:text-[#1CC7C0] md:capitalize my-3 md:my-0 ${pathname  === ENDPOINT_PORTFOLIO ? 'text-[#1CC7C0]':'text-white' } `}>portfolio</li>
            </Link>
          
          <Link to={ENDPOINT_CONTACT_US} className=''>

          <button className={` font-satoshi font-medium  text-white text-[16px] md:text-[16px] capitalize border px-4 py-2 border-[#1CC7C0] my-1 md:my-0 text-center  outline-none md:capitalize ${pathname  === ENDPOINT_CONTACT_US ? 'text-[#1CC7C0]':'text-white' } `}>Contact us</button>
          </Link>

        </ul>

    </nav>
  )
}

export default Topbar