import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Portfolio from '../components/Portfolio'
import PortfolioHero from '../components/PortfolioHero'
import Topbar from '../components/Topbar'

function PortfolioPage() {
  return (
    <>
    {/* <Navbar /> */}
    <Topbar />
    {/* <PortfolioHero /> */}
    <Portfolio />
    <Footer />
    </>
  )
}

export default PortfolioPage
