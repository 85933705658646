import React, { useEffect, useState } from 'react'

function PortfolioHero({images, activeId}) {
    // const images= [
    //     {id:1,
    //     url:'/cdn/images/ks.png'
    //   },
    //   {id:2,
    //     url:'/cdn/images/hero.png'
    //   },
    //   {id:3,
    //     url:'/cdn/images/ks2.png'
    //   },
    //   {id:4,
    //     url:'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'
    //   },
    //   {id:5,
    //     url:'/cdn/images/ks4.png'
    //   },
    //   {id:6,
    //     url:'/cdn/images/ks5.png'
    //   },
    //   ]
      const [currentIndex, setCurrentIndex] = useState(activeId)
      const prevSlide = () => {
        const isFirstSlide = currentIndex === 0
        const nextSlide = isFirstSlide ? images.length - 1 : currentIndex - 1
        setCurrentIndex(nextSlide)
    
    
      }
    
      const nextSlide = () => {
        const isLastSlide = currentIndex === images.length - 1
        const newIndex = isLastSlide ? 0 : currentIndex + 1
        setCurrentIndex(newIndex)
        
      }
      const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)
    
      }
      // useEffect(() => {
      //   setTimeout(() => {
      //       prevSlide()
      //   }, 7000);
      // }, [currentIndex])
  return (
    <>
    <div className=" h-screen  w-full  relative custom-scrollbar">
      <div
      style={{backgroundImage: `url(${images[currentIndex].url})`}}
       className="w-full h-full  bg-center bg-cover ease-in duration-500"> </div>
        <div
        onClick={prevSlide}
         className="absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl bg-gray-900/80 rounded-full p-4 bg-black/2 text-white cursor-pointer">

        <svg
        onClick={prevSlide}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
        </svg>
        </div>

        <div
        onClick={nextSlide}
         className="absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl bg-gray-900/80 rounded-full p-4 bg-black/2 text-white cursor-pointer">

        <svg
        onClick={nextSlide}
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
        </svg>
        </div>
        <div className="flex -top-4 py-2 justify-center">
          {/* {images.map((image, index) =>(
            <div
            onClick={() => goToSlide(index)}
             className={` ${currentIndex === index ? 'bg-gray-400': 'bg-black'} m-1 rounded-full p-2`}></div>

          ))} */}
        </div>




      
    </div>
    </>
  )
}

export default PortfolioHero