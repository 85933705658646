import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import PortfolioHero from '../components/PortfolioHero'
import Topbar from '../components/Topbar'
import Hero from '../components/Hero'



function PortfolioDetailsPage() {
    const images= [
        {id:0,
            url:'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'
          },
        {id:1,
        url:'/cdn/images/ks.png'
      },
      {id:2,
        url:'/cdn/images/hero.png'
      },
      {id:3,
        url:'/cdn/images/ks2.png'
      },
      {id:4,
        url:'https://images.pexels.com/photos/38238/maldives-ile-beach-sun-38238.jpeg'
      },
      {id:5,
        url:'/cdn/images/ks4.png'
      },
      ]
      const [currentIndex, setCurrentIndex] = useState(0)
      const [showImgFullscreen, setShowImgFullscreen] = useState(false)


      const handleClose = () =>{

        setShowImgFullscreen(false)
        const anchor = document.querySelector('#prof-images')
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    
      }

      const showFullImageMobile = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        setShowImgFullscreen(true)

      }

      const showFullImageDesktop = () => {
        setShowImgFullscreen(true)

      }

      const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

      useEffect(() => {
        window.addEventListener("resize", () => {
          const ismobile = window.innerWidth < 1200;
          if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
      }, [isMobile]);
  return (
    <>
    {/* <Navbar /> */}
    <Topbar />
    <PortfolioHero images={images} activeId={images[0].id}/>
    <div className="h-full ">

    <div className={`flex flex-col w-full py-20 md:py-40  ${showImgFullscreen ? 'px-0 md:px-40':'md:px-40'}`}>
      <div className="flex flex-col md:flex-row gap-4 md:gap-10">
        <div className="w-full md:w-3/12">
        <p className='font-satoshi  text-[16px] md:text-[24px] font-semibold uppercase text-white py-3 px-2 md:px-0'>Village market
    </p>
        </div>
        <div className="w-full md:w-9/12">
        <p className='font-satoshi  text-[16px] md:text-[20px] font-medium text-white py-3 px-2 md:px-0'>
        Aspera Design was commissioned to provide full design services for Mbaazi Tower, an iconic Mixed-Use Development on a 2-acre plot off Mbaazi Avenue, Nairobi. The client’s brief required an iconic and high-end, striking design comprising of retail, hotel, serviced and apartment tower. The tower, comprises of 35 storeys on 3 basement and 3 podium floors. It is configured as a Y-shaped plan that rises to 140m. Each face is curved, forming the tower’s tri-concave arrangement; that would capitalize on 3 key directional views at the higher floors:  the Nairobi CBD skyline,  the new emerging Westlands skyline and the Ngong Hills. The podium consists of 3 separate drop-offs, one serving as the retail and office access, the other the hotel entrance and the 3rd one is a residential access.
        The podium consists of double height retail floors, each fronted with full height glazed walls. The shops open as a showpiece to the entrance forecourt and foyer, creating a lively space that compliments the luxury hotel lobby on one aspect and luxury apartments on the other . The upper  portion of the podium consists of meetings and conference facilities as well as a hotel leisure and pool deck. The rear aspect of the podium opens out onto an expansive recreational garden area for the residents. The tower consists of 3 typical office floors, 80 hotel rooms from level 4 to 6, 150 hotel rooms from level 7 to 17, 30 2-bedroom serviced apartments from level 15 to 19, and a mix of 110 2 and 3-bedroom apartments from levels 20 to 30. The tower façade is characterized by a glazed slit that defines the floor to floor height glazing to all the bedroom and living spaces shaded by continuous strip balconies that provide shading, solid  walls and punched window openings to the bathroom and utility rooms. The top of the tower contains a wind turbine that is inscribed in the tower pinnacle.


          </p>
        </div>

      </div>


    <div id='prof-images' className="flex flex-col-reverse md:flex-row w-full gap-3 relative">

    <div className="w-full md:w-[30%]">
        <div className="grid grid-cols-2 grid-rows-6 h-[80vh] gap-1">
            {images?.map((image) => (

          <img
          loading='lazy'
          onClick={() => setCurrentIndex(image.id)}
           className='w-full h-full object-cover row-span-2 relative' src={image.url} alt="" />
            ))}


        </div>
      </div>
      <div className="w-full md:w-[70%] relative">
      <img 
      onClick={showFullImageMobile}
      loading='lazy'
      className='w-full h-full relative object-cover cursor-pointer row-span-3 relative' src={images[currentIndex].url} alt="" />
      {!showImgFullscreen &&
      <div
      onClick={showFullImageMobile}
       className="absolute top-0 right-0 bg-gray-900/20 z-50 rounded-full p-3">
      <svg
      onClick={showFullImageMobile}
       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-7 h-7 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>

      </div>
      }



      </div>
      {false && 
      <>
      {isMobile ? 
      <>
      <div className="w-full md:w-[30%]">
        <div className="grid grid-cols-2 grid-rows-6 h-[80vh] gap-1">
            {images?.map((image) => (

          <img
          loading='lazy'
          onClick={() => setCurrentIndex(image.id)}
           className='w-full h-full object-cover row-span-2 relative' src={image.url} alt="" />
            ))}


        </div>
      </div>
      <div className="w-full md:w-[70%]">
      <img 
      onClick={showFullImageMobile}
      loading='lazy'
      className='w-full h-full object-cover cursor-pointer row-span-3 relative' src={images[currentIndex].url} alt="" />



      </div>

</>
:
  <>

{showImgFullscreen ?
<div className="w-full h-screen">

<Hero images={images} activeId={currentIndex} handleClose={handleClose}/>

</div>
:

<>
      <div className="w-full md:w-[30%]">
        <div className="grid grid-cols-2 grid-rows-6 h-[80vh] gap-1">
            {images?.map((image) => (

          <img
          loading='lazy'
          onClick={() => setCurrentIndex(image.id)}
           className='w-full h-full object-cover row-span-2 relative' src={image.url} alt="" />
            ))}


        </div>
      </div>
      <div className="w-full md:w-[70%] relative">
      <img 
      onClick={isMobile ? showFullImageMobile : showFullImageDesktop}
      loading='lazy'
      className='w-full h-full object-cover cursor-pointer row-span-3 relative' src={images[currentIndex].url} alt="" />
      <div
      onClick={isMobile ? showFullImageMobile : showFullImageDesktop}
       className="absolute top-0 right-0 bg-gray-900/30 z-50">
      <svg
      onClick={isMobile ? showFullImageMobile : showFullImageDesktop}
       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-white w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>

      </div>



      </div>

</>
}
</>
}
</>}



      </div>
    </div>
    {showImgFullscreen && 
    <div className="w-full h-screen absolute top-0">
    <Hero images={images} activeId={currentIndex} handleClose={handleClose}/>
    </div>
    }

{/* <button onClick={handleClose} className='bg-white text-black'>Scroll to images</button> */}

    </div>

    <Footer />
    </>
  )
}

export default PortfolioDetailsPage